<template>
	<div class="dashboard-container">
		<Operate v-if="dialog.refresh" :dialog.sync="dialog.show" :row="dialog.row"
             :info="dialog.info"
             @get-list="getList"
		 @refresh="refreshDialog" />


		<el-card >
			<el-row type="flex" justify="space-between" :gutter="20">
				<el-col>
					<span class="text-primary text-pad-right">发布状态</span>
					<el-select v-model="ajaxParams.status" clearable size="mini" class="input searchInput" @change="()=>{
                       ajaxParams.currentPage = 1
                        getList()
                        $refs.table.clearCheckboxRow()
					}">
						<el-option :value="1" label="已发布" />
						<el-option :value="0" label="未发布" />
					</el-select>
				</el-col>
				<el-col>
					<span class="text-primary text-pad-right">日期</span>
					<el-date-picker v-model="daterange" type="daterange" class="input searchInput" range-separator="至"
					 start-placeholder="开始日期" end-placeholder="结束日期" clearable value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']"
					 size="mini" @change="(val)=>{
                         ajaxParams.currentPage = 1;
                         val?(
                             ajaxParams.startTime = val[0]
                         ):(
                             ajaxParams.startTime = null
                         );
                         val?(
                             ajaxParams.endTime = val[1]
                         ):(
                             ajaxParams.endTime = null
                         );
                         getList()
					 }" />
				</el-col>
				<el-col>
					<el-input v-model="ajaxParams.title" class="input searchInput" size="mini" suffix-icon="el-icon-search"
					 placeholder="搜索" @input="getList()" />
				</el-col>
			</el-row>
		</el-card>
		<el-card  style="margin-top: 20px;">
			<el-row>
				<vxe-toolbar>
					<template v-slot:buttons>
						<el-row type="flex" justify="between">
							<el-col>
								<el-button v-if=" $hasBtn(
                                            'sys:permissions:business:exposurePlatform:add'
                                        )
                                    " type="primary" size="mini" @click="openDialog({title:'添加',addOr:'add'})">添加</el-button>
								<el-button v-if="$hasBtn(
                                            'sys:permissions:business:exposurePlatform:batDel'
                                        )
                                    " type="danger" size="mini" @click="remove({del:'batch'})">批量删除</el-button>
							</el-col>
							<el-col :span="1">
								<vxe-button @click="()=>{
                  daterange = null;
                  ajaxParams.currentPage= 1;
                 ajaxParams. endTime= null;
                  ajaxParams.pageSize= 20;
                  ajaxParams.startTime=null;
                  ajaxParams.status= null;
                  ajaxParams.title= null;
                 getList();
								}">刷新</vxe-button>
							</el-col>
						</el-row>
					</template>
				</vxe-toolbar>
				<vxe-table ref="table" border resizable :auto-resize="true"
                   :header-row-style="headerStyle"
                   :row-style="rowStyle"
				 align="center" class="vxe-table-element" height="620" row-id="notice_id"
                   :sort-config="{remote: true}"
				 :filter-config="{remote: true}"
                   :checkbox-config="{reserve: true}" :data="list" :loading="loading">
					<vxe-table-column type="checkbox" width="60" fixed="left" />
					<vxe-table-column type="seq" width="60" />
					<vxe-table-column field="title" title="标题" show-overflow="title" />
					<vxe-table-column field="createTime" title="创建时间" show-overflow="title" />
					<vxe-table-column field="releaseTime" title="发布时间" show-overflow="title" />
					<vxe-table-column title="状态">
						<template v-slot="{ row }">
							<div :style="row.status===1?`color:#2391FF`:``">{{ row.statusStr }}</div>
<!--                发布状态0未发布1发布-->
						</template>
					</vxe-table-column>
					<vxe-table-column field="createName" title="创建人" show-overflow="title"/>

					<vxe-table-column field="" title="操作">
						<template v-slot="{ row, rowIndex }">
							<el-dropdown size="small" type="primary">
								<el-button size="small" type="primary">
									操作<i class="el-icon-arrow-down el-icon--right" />
								</el-button>
								<el-dropdown-menu slot="dropdown">

									<el-dropdown-item v-if="$hasBtn(
                                            'sys:permissions:business:exposurePlatform:see'
                                        )
                                    "  icon="el-icon-edit" @click.native="openDialog({title:'查看',addOr:'view'},row)">查看</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn(
                                            'sys:permissions:business:exposurePlatform:issue'
                                        )
                                    "  icon="el-icon-thumb" @click.native="release(row)" :disabled="row.status===1?true:false">发布</el-dropdown-item>
									<el-dropdown-item v-if="$hasBtn(
                                            'sys:permissions:business:exposurePlatform:del'
                                        )
                                    "  icon="el-icon-delete" @click.native="remove({del:'single'},row)">删除</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</template>
					</vxe-table-column>
				</vxe-table>
				<div style="height: 20px" />
				<el-pagination background :current-page="ajaxParams.currentPage" :page-sizes="[5, 10, 15, 20, 50, 100]"
				 :page-size="ajaxParams.pageSize" layout="total, ->, sizes, prev, pager, next, jumper" :total="total"
                       @size-change="(pageSize)=>{
                           this.ajaxParams.pageSize = pageSize
                          this.ajaxParams.currentPage = 1
                          this.getList()
                       }"
				 @current-change="(currentPage)=>{
                      this.ajaxParams.currentPage = currentPage;
				              this.getList()
				              }"
        />
			</el-row>
		</el-card>

	</div>
</template>

<script>
	import {
		rowStyle,
		headerStyle
	} from '@/utils/tableStyleJs'

	import Operate from './Operate'
	export default {
		name: 'Index',
		components: {
			Operate,
		},
		data() {
			return {
				loading: false,
          daterange:null,
         ajaxParams:{
             currentPage: 1,
             direction: null,
             endTime: null,
             pageSize: 20,
             property: null,
             startTime: null,
             status: null,
             title: null,
         },

          list: [],
          total: 0,

				dialog: {
					row: Object,
					info: {},
					refresh: false, // 每次刷新DOM
					refresh2: false, // 每次刷新DOM
					show: false,
					show2: false
				},

			}
		},
		created() {
            this.$nextTick(()=>{
                this.getList();
            });
		},
		methods: {
			rowStyle,
			headerStyle,
			// 打开dialog
			openDialog(info, row) {
				this.dialog.refresh = true
				this.dialog.show = true
				this.dialog.info = info
				this.dialog.row = row
			},



			async getList() {
          this.$refs.table.setAllCheckboxRow(false);
          // this.loading = true
           let {code,data}= await    this.$api.get("/info/server/escort/web/exposure/stage",{
               params:this.ajaxParams
           })
          if(code==200){
              this.list = data.content;
              this.total = data.totalElements*1;
              this.loading = false;
          }
          this.loading = false;
			},

			remove(info, row) {
				const id = []
				if (info.del === 'batch') {
					const arr = this.$refs.table.getCheckboxRecords()
					const arr1 = this.$refs.table.getCheckboxReserveRecords()
					const batchDel = arr.concat(arr1)
					for (const i of batchDel) {
						id.push(i.id)
					}
					if (id.length === 0) {
						this.$message.error('请勾选！！！')
						return
					}
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async() => {
              let {code,msg}= await this.$api.delete(`/info/server/escort/web/exposure/stage/ids/${id+''}`,{})
              if(code==200){
                  this.$message.success(msg)
                  await this.getList()
              }
					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				} else {
					this.$confirm('确认删除吗！！！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(async() => {
              let {code,msg}= await this.$api.delete(`/info/server/escort/web/exposure/stage/${row.id}`,{})
              if(code==200){
                  this.$message.success(msg)
                  await this.getList()
              }

					}).catch(() => {
						this.$message.info('取消了删除！！！')
					})
				}
			},
			release(row) {
				this.$confirm('确认发布吗！！！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(async() => {
                let {code,msg}= await this.$api.put(`/info/server/escort/web/exposure/stage/release/${row.id}`,{})
                if(code==200){
                    this.$message.success(msg)
                    this.getList()
                }
				}).catch(() => {
					this.$message.info('取消了发布！！！')
				})
			},


			refreshDialog() {
				this.dialog.refresh = false
			},




		}
	}
</script>

<style scoped>
	.textHide {
		/* width: 130px; */
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		color: #1890FF;
		cursor: pointer;
	}

	.text-center {
		/* color: #1890FF; */
		font-size: 20px;
		font-weight: bold;
		padding: 20px;
	}

	.infoBox {
		border: 1px solid #CCCCCC;
		font-size: 16px;
		height: calc(100vh - 180px);
		overflow-y: scroll;
		overflow-x: hidden;
		-ms-overflow-style: none;
		overflow: -moz-scrollbars-none;
	}

	.infoBox::-webkit-scrollbar {
		display: none;
	}

	.border-b {
		border-bottom: 1px solid #CCCCCC;
	}

	.border-r {
		border-right: 1px solid #CCCCCC;
	}

	.col-1890FF {
		color: #1890FF;
	}

	.box1 {
		width: 10%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

	.box2 {
		width: 18%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}

	.box3 {
		width: 90%;
		height: 230px;
		text-align: center;
		overflow-y: scroll;
	}

	.box13 {
		width: 10%;
		height: 230px;
		line-height: 230px;
		text-align: center;
	}
</style>
