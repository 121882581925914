<template>
    <el-dialog
        :visible.sync="show"
        width="50%"
        :append-to-body="true"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        @close="close"
    >
        <div slot="title" class="header-title">
            <el-row :gutter="5">
                <el-col :span="24">
                    <span class="title-name">{{ info.title }}</span>
                </el-col>
            </el-row>
        </div>
        <el-row :gutter="0">
            <el-col :span="24">
                <el-form
                    ref="ruleForm"
                    label-width="110px"
                    class="allForm"
                    :disabled="info.addOr === 'view'"
                >
                    <el-form-item label="标题" :required="true">
                        <el-input v-model="form.title" class="formInput" />
                    </el-form-item>

                    <el-form-item
                        label="公司名称"
                        :required="true"
                        class="formInput"
                    >
                        <el-select
                            v-model="form.companyId"
                            class="w-100"
                            filterable
                            clearable
                            @change="() => {}"
                        >
                            <el-option
                                v-for="v in company"
                                :key="v.id"
                                :value="v.id"
                                :label="v.companyName"
                            />
                        </el-select>
                    </el-form-item>

                    <el-form-item
                        label="公告内容"
                        :required="true"
                        class="formInput"
                    >
                        <!--                        <wang-->
                        <!--                            :content-back="form.content"-->
                        <!--                            :disabled="info.addOr === 'view'"-->
                        <!--                            @get-content="getContent"-->
                        <!--                        />-->
                        <RichText
                            v-if="info.addOr !== 'view'"
                            @get-content="getContent"
                        />

                        <div
                            v-else
                            style="border: 1px solid #e6ebf5; padding: 5px 10px"
                            v-html="form.content"
                        ></div>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <div
            v-if="info.addOr !== 'view'"
            slot="footer"
            class="dialog-footer"
            style="text-align: center"
        >
            <el-button type="primary" @click="commit">提交</el-button>
            <el-button type="info" @click="close">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import wang from "@/components/WangEditor";
import { company } from "@/api";
import { uploadAccept } from "@/utils/uploadAccept";
import { downFile } from "@/utils/file";
import { axiosReqCross } from "@/utils/request";
import RichText from "./RichText.vue";
export default {
    name: "Operate",
    components: {
        wang,
        RichText,
    },
    props: {
        // 打开dialog的
        dialog: {
            type: Boolean, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: false, // 这样可以指定默认的值
        },
        info: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
        row: {
            type: Object, // 指定传入的类型
            // type 也可以是一个自定义构造器函数，使用 instanceof 检测。
            default: () => {}, // 这样可以指定默认的值
        },
    },
    data() {
        return {
            show: this.dialog,
            form: {
                companyId: null,
                content: null,
                title: null,
            },
            company: [],

            loading: false,
        };
    },
    created() {
        this.getCompany();
        console.log(window.sessionStorage.CompanyId);
        this.form.companyId = window.sessionStorage.CompanyId;
    },
    mounted() {
        this.$nextTick(() => {
            this.upd();
        });
    },
    methods: {
        loadingloading(val) {
            this.loading = val;
        },
        async upd() {
            if (this.info.addOr !== "add") {
                let { code, data } = await this.$api.get(
                    `/info/server/escort/web/exposure/stage/${this.row.id}`
                );
                if (code == 200) {
                    this.form.companyId = data.companyId;
                    this.form.content = data.content;
                    this.form.title = data.title;
                    this.$nextTick(() => {
                        var videos = document.getElementsByTagName("video"); //获取所有video
                        //循环给所有video添加监听事件 当前的video开始播时  调用pauseAll 将当前播放的video的索引传值过去
                        for (var i = videos.length - 1; i >= 0; i--) {
                            (function (n) {
                                videos[n].addEventListener("play", function () {
                                    pauseAll(n);
                                });
                            })(i);
                        }
                        //接收调用传来的索引 循环所有video 索引与传来的索引不相同的 暂停 重载
                        function pauseAll(index) {
                            for (var j = videos.length - 1; j >= 0; j--) {
                                if (j != index) {
                                    videos[j].pause();
                                    videos[j].load();
                                }
                            }
                        }
                    });
                }
            }
        },

        getContent(v) {
            // console.log(v.content)
            this.form.content = v.content;
            //       this.form.content = v.noHtml;
        },
        getCompany() {
            this.$axiosReq(
                company,
                null,
                {
                    pageSize: 10000,
                },
                "get"
            ).then((res) => {
                this.company = res.data.content;
            });
        },

        // 提交添加
        async commit() {
            if (this.$textNull(this.form.title)) {
                this.$message.error("请填标题！");
                return;
            } else if (this.$textNull(this.form.content)) {
                this.$message.error("请输入内容文件");
                return;
            } else if (this.$textNull(this.form.companyId)) {
                this.$message.error("请选择公司！");
                return;
            }

            let formData = new FormData();

            Object.keys(this.form).forEach((item) => {
                this.form[item] && formData.append(item, this.form[item]);
            });

            let { code, msg } = await this.$api.post(
                `/info/server/escort/web/exposure/stage`,
                formData,
                {
                    // params:this.form
                    headers: {
                        "content-type": "multipart/form-data",
                    },
                }
            );
            if (code == 200) {
                this.$message.success(msg);
                this.close();
                this.$emit("get-list");
            }
        },
        // 关闭模态框
        close() {
            this.show = false;
            this.$emit("refresh");
        },
    },
};
</script>

<style scoped lang="scss">
.el-form {
    padding-right: 25px;
}

::v-deep .editor {
    .text {
        background-color: #fff !important;
    }
}
</style>
